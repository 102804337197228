const PREFIX = 'point-details'

export default {
  editContainer: `${PREFIX}-editContainer`,

  pointName: `${PREFIX}-pointName`,
  partnerCode: `${PREFIX}-partnerCode`,
  partnerOrganization: `${PREFIX}-partnerOrganization`,
  partnerOrganizationChange: `${PREFIX}-partnerOrganizationChange`,
  partnerOrganizationChangeCancel: `${PREFIX}-partnerOrganizationChangeCancel`,
  address: `${PREFIX}-address`,
  isOnline: `${PREFIX}-isOnline`,
  isInitialPaymentCorrection: `${PREFIX}-isInitialPaymentCorrection`,
  isRoundCents: `${PREFIX}-isRoundCents`,
  isFreezeInitialPayment: `${PREFIX}-isFreezeInitialPayment`,
  retail: `${PREFIX}-retail`,
  segment: `${PREFIX}-segment`,
  macroRegion: `${PREFIX}-macroRegion`,
  partnerBank: `${PREFIX}-partnerBank`,

  addManager: `${PREFIX}-addManager`,
  manager: {
    htmlID: `${PREFIX}-manager`,

    id: `${PREFIX}-id`,
    startDate: `${PREFIX}-startDate`,
    delete: `${PREFIX}-delete`
  },

  isSendSalesReport: `${PREFIX}-isSendSalesReport`,
  addReport: `${PREFIX}-addReport`,
  sale: {
    htmlID: `${PREFIX}-sale`,

    email: `${PREFIX}-email`,
    delete: `${PREFIX}-delete`
  },

  callCenter: `${PREFIX}-callCenter`,
  callCenterAgent: `${PREFIX}-callCenterAgent`,
  isCallCenterScansRequired: `${PREFIX}-isCallCenterScansRequired`,
  callCenterInfo: {
    block: `${PREFIX}-callCenterInfo-block`,
    input: `${PREFIX}-callCenterInfo-input`,
    saveButton: `${PREFIX}-callCenterInfo-saveButton`
  },
  isCallCenterWidgetSendToDefaultOwner: `${PREFIX}-isCallCenterWidgetSendToDefaultOwner`,

  pointServices: `${PREFIX}-pointServices`,

  savePoint: `${PREFIX}-savePoint`,

  detailedTabs: `${PREFIX}-detailedTabs`,

  sales: {
    block: `${PREFIX}-block`,

    bankPiecesTable: `${PREFIX}-bankPiecesTable`,
    bankPiecesBank: `${PREFIX}-bankPiecesTable-bank`,
    bankPiecesPiece: `${PREFIX}-bankPiecesTable-piece`,
    bankPiecesAmount: `${PREFIX}-bankPiecesTable-amount`,

    insurancePiecesTable: `${PREFIX}-insurancePiecesTable`,
    insurancePiecesTableBank: `${PREFIX}-insurancePiecesTable-bank`,
    insurancePiecesTablePenetration: `${PREFIX}-insurancePiecesTable-penetration`,
    insurancePiecesTableBankInsurance: `${PREFIX}-insurancePiecesTable-bankInsurance`,
    insurancePiecesTableExternalInsurance: `${PREFIX}-insurancePiecesTable-externalInsurance`,
    insurancePiecesTableWithoutInsurance: `${PREFIX}-insurancePiecesTable-withoutInsurance`,
    insurancePiecesTableTotal: `${PREFIX}-insurancePiecesTable-total`,

    smsPiecesTable: `${PREFIX}-smsPiecesTable`,
    smsPiecesTableBank: `${PREFIX}-smsPiecesTable-bank`,
    smsPiecesTablePenetration: `${PREFIX}-smsPiecesTable-penetration`,
    smsPiecesTableBankSms: `${PREFIX}-smsPiecesTable-bankSms`,
    smsPiecesTableExternalSms: `${PREFIX}-smsPiecesTable-externalSms`,
    smsPiecesTableWithoutSms: `${PREFIX}-smsPiecesTable-withoutSms`,
    smsPiecesTableTotal: `${PREFIX}-smsPiecesTable-total`,

    servicesPiecesTable: `${PREFIX}-servicesPiecesTable`,
    servicesPiecesTableBank: `${PREFIX}-servicesPiecesTable-bank`,
    servicesPiecesTablePenetration: `${PREFIX}-servicesPiecesTable-penetration`,
    servicesPiecesTableTotal: `${PREFIX}-servicesPiecesTable-total`,
    servicesPiecesTableBankTotal: `${PREFIX}-servicesPiecesTable-bankTotal`,
    servicesPiecesTableRange: `${PREFIX}-servicesPiecesTable-range`
  },
  history: {
    list: `${PREFIX}-history-list`,
    user: `${PREFIX}-history-list-user`,
    time: `${PREFIX}-history-list-time`,
    changes: `${PREFIX}-history-list-changes`
  },
  codes: {
    bank: `${PREFIX}-codes-bank`,
    add: `${PREFIX}-codes-add`,
    value: `${PREFIX}-codes-value`,
    delete: `${PREFIX}-codes-delete`,
    saveCodes: `${PREFIX}-codes-saveCodes`
  },
  AgentCommissionGroups: {
    block: `${PREFIX}-agent-commission-groups-block`,
    select: `${PREFIX}-agent-commission-groups-select`,
    add: `${PREFIX}-agent-commission-groups-add`,
    startsOn: `${PREFIX}-agent-commission-starts-on`,
    save: `${PREFIX}-agent-commission-save`
  },
  accreditation: {
    block: `${PREFIX}-accreditation-block`,
    list: `${PREFIX}-accreditation-list`,
    bank: `${PREFIX}-accreditation-bank`,
    status: `${PREFIX}-accreditation-status`,
    accredit: `${PREFIX}-accreditation-accredit`
  }
}
