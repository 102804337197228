const PREFIX = 'organization-details'

export default {
  editContainer: `${PREFIX}-editContainer`,

  name: `${PREFIX}-name`,
  inn: `${PREFIX}-inn`,
  kpp: `${PREFIX}-kpp`,
  vatType: `${PREFIX}-vatType`,
  address: `${PREFIX}-address`,
  urAddress: `${PREFIX}-urAddress`,
  contractNo: `${PREFIX}-contractNo`,
  contractDate: `${PREFIX}-contractDate`,
  estoreContacts: `${PREFIX}-estoreContacts`,
  logoUrl: `${PREFIX}-logoUrl`,
  mainPartner: `${PREFIX}-mainPartner`,
  showServicesInSpecification: `${PREFIX}-showServicesInSpecification`,
  allowSendWithoutConfirmationCode: `${PREFIX}-allowSendWithoutConfirmationCode`,
  allowSendWithoutPdAgreement: `${PREFIX}-allowSendWithoutPdAgreement`,
  allowPassportRecognition: `${PREFIX}-allowPassportRecognition`,
  printCreditPaymentsMemo: `${PREFIX}-printCreditPaymentsMemo`,
  clientVerification: `${PREFIX}-clientVerification`,
  channel: `${PREFIX}-channel`,
  bankAccount: {
    addBankAccount: `${PREFIX}-bankAccount-addBankAccount`,
    bik: `${PREFIX}-bankAccount-bik`,
    rs: `${PREFIX}-bankAccount-rs`,
    create: `${PREFIX}-bankAccount-create`,
    update: `${PREFIX}-bankAccount-update`,
    cancel: `${PREFIX}-bankAccount-cancel`,
    pointsAssignOpen: `${PREFIX}-bankAccount-pointsAssignOpen`,
    singlePointAssign: `${PREFIX}-bankAccount-singlePointAssign`,
    pointsAssign: `${PREFIX}-bankAccount-pointsAssign`,
    lock: `${PREFIX}-bankAccount-lock`,
    remove: `${PREFIX}-bankAccount-remove`,

    searchPoint: `${PREFIX}-bankAccount-searchPoint`,
    oldPoint: `${PREFIX}-bankAccount-oldPoint`,
    selectPoint: `${PREFIX}-bankAccount-selectPoint`
  },
  contact: {
    addContact: `${PREFIX}-contact-addContact`,

    email: `${PREFIX}-contact-email`,
    phone: `${PREFIX}-contact-phone`,
    fullName: `${PREFIX}-contact-fullName`,
    position: `${PREFIX}-contact-position`,
    delete: `${PREFIX}-contact-delete`
  },
  savePartner: `${PREFIX}-save`,
  tabs: `${PREFIX}-tabs`,

  codes: {
    bank: `${PREFIX}-codes-bank`,
    add: `${PREFIX}-codes-add`,
    value: `${PREFIX}-codes-value`,
    delete: `${PREFIX}-codes-delete`,
    saveCodes: `${PREFIX}-codes-saveCodes`
  },

  history: {
    list: `${PREFIX}-history-list`,
    user: `${PREFIX}-history-list-user`,
    time: `${PREFIX}-history-list-time`,
    changes: `${PREFIX}-history-list-changes`
  },

  settings: {
    block: `${PREFIX}-settings-block`,
    list: `${PREFIX}-settings-list`,
    bank: `${PREFIX}-settings-bank`,
    isDirectFinancing: `${PREFIX}-settings-isDirectFinancing`,
    isGoodsCostIncludeServices: `${PREFIX}-settings-isGoodsCostIncludeServices`,
    isHireDiscount: `${PREFIX}-settings-isHireDiscount`,
    isDiscountByTermEnabled: `${PREFIX}-settings-isDiscountByTermEnabled`,
    discountByTermButton: `${PREFIX}-settings-discountByTermButton`,
    discountMax: `${PREFIX}-settings-discountMax`,
    save: `${PREFIX}-settings-save`
  },
  documents: {
    block: `${PREFIX}-documents-block`,
    dateTurnOff: `${PREFIX}-documents-dateTurnOff`,
    createdAt: `${PREFIX}-documents-createdAt`,
    isAutoDeactivationDisabled: `${PREFIX}-documents-isAutoDeactivationDisabled`,
    save: `${PREFIX}-documents-save`,
    bundle: `${PREFIX}-documents-bundle`,
    scan: `${PREFIX}-documents-scan`,
    renameScanOpen: `${PREFIX}-documents-renameScanOpen`,
    renameScanSave: `${PREFIX}-documents-renameScanSave`,
    renameScanInput: `${PREFIX}-documents-renameScanInput`,
    renameScanCancel: `${PREFIX}-documents-renameScanCancel`,
    addScanOpen: `${PREFIX}-documents-addScanOpen`,
    addScanSave: `${PREFIX}-documents-addScanSave`,
    addScanInput: `${PREFIX}-documents-addScanInput`,
    addScanCancel: `${PREFIX}-documents-addScanCancel`,
    uploadScan: `${PREFIX}-documents-uploadScan`,
    deleteScan: `${PREFIX}-documents-deleteScan`,
    dropzone: `${PREFIX}-documents-dropzone`
  }
}
