const PREFIX = 'accreditation'

export default {
  page: `${PREFIX}-page`,
  list: {
    bank: `${PREFIX}-bank`,
    point: `${PREFIX}-point`,
    user: `${PREFIX}-user`,
    table: `${PREFIX}-list`
  }
}
