const PREFIX = 'questionary'

export default {
  phone: `${PREFIX}-phone`,
  findClientsByPhone: `${PREFIX}-findClientsByPhone`,
  findClientsByPhoneSelect: `${PREFIX}-findClientsByPhone-select`,
  passport: `${PREFIX}-passport`,
  passportDate: `${PREFIX}-passportDate`,
  findClientsByPassport: `${PREFIX}-findClientsByPassport`,
  findClientsByPassportSelect: `${PREFIX}-findClientsByPassport-select`,
  fio: `${PREFIX}-fio`,
  gender: `${PREFIX}-gender`,
  birthDate: `${PREFIX}-birthDate`,
  income: `${PREFIX}-income`,
  isCheat: `${PREFIX}-isCheat`,

  back: `${PREFIX}-back`,
  next: `${PREFIX}-next`,
  uncleSam: `${PREFIX}-uncleSam`,
  callCenter: `${PREFIX}-callCenter`,

  orders: {
    addOrder: `${PREFIX}-orders-addOrder`,

    orderName: `${PREFIX}-orders-orderName`,
    producer: `${PREFIX}-orders-producer`,
    category: `${PREFIX}-orders-category`,
    cost: `${PREFIX}-orders-cost`,
    count: `${PREFIX}-orders-count`,
    add: `${PREFIX}-orders-add`,
    subtraction: `${PREFIX}-orders-subtraction`,
    remove: `${PREFIX}-orders-remove`
  },

  additional: {
    passportCode: `${PREFIX}-passportCode`,
    passportBy: `${PREFIX}-passportBy`,
    birthPlace: `${PREFIX}-birthPlace`,
    addressRegistration: `${PREFIX}-addressRegistration`,
    registrationDate: `${PREFIX}-registrationDate`,
    passportMainScan: `${PREFIX}-passportMainScan`,
    passport19Scan: `${PREFIX}-passport19Scan`,
    passportRegScan: `${PREFIX}-passportRegScan`,
    residenceAddress: `${PREFIX}-residenceAddress`,
    residenceDate: `${PREFIX}-residenceDate`,
    isRepeatResidenceAddress: `${PREFIX}-isRepeatResidenceAddress`,
    prevPassport: `${PREFIX}-prevPassport`,
    isDisablePrevPassport: `${PREFIX}-isDisablePrevPassport`
  },

  other: {
    photoPreview: `${PREFIX}-photoPreview`,
    photoUrl: `${PREFIX}-photoUrl`,
    addPhoto: `${PREFIX}-addPhoto`,
    withoutPhoto: `${PREFIX}-withoutPhoto`,
    camera: `${PREFIX}-camera`,
    upload: `${PREFIX}-upload`,

    email: `${PREFIX}-email`,

    education: `${PREFIX}-education`,

    familyType: `${PREFIX}-familyType`,
    familyChildren: `${PREFIX}-familyChildren`,
    familyChildrenAdd: `${PREFIX}-familyChildrenAdd`,
    familyChildrenSubtraction: `${PREFIX}-familyChildrenSubtraction`,
    familySpouseFullName: `${PREFIX}-familySpouseFullName`,

    employmentType: `${PREFIX}-employmentType`,
    landlinePhone: `${PREFIX}-landlinePhone`,
    organization: `${PREFIX}-organization`,
    organizationAddress: `${PREFIX}-organizationAddress`,
    organizationInn: `${PREFIX}-organizationInn`,
    ownershipType: `${PREFIX}-ownershipType`,
    bussinessType: `${PREFIX}-bussinessType`,
    activityType: `${PREFIX}-activityType`,
    employmentPosition: `${PREFIX}-employmentPosition`,
    employmentDate: `${PREFIX}-employmentDate`,
    workPhone: `${PREFIX}-workPhone`,

    incomeAdd: `${PREFIX}-incomeAdd`,
    incomeType: `${PREFIX}-incomeType`,
    incomePrice: `${PREFIX}-incomePrice`,
    incomeRemove: `${PREFIX}-incomeRemove`,

    contactFirstName: `${PREFIX}-contactFirstName`,
    contactPatronymic: `${PREFIX}-contactPatronymic`,
    contactStatus: `${PREFIX}-contactStatus`,
    contactPhone: `${PREFIX}-contactPhone`,

    realtyAdd: `${PREFIX}-realtyAdd`,
    realtyType: `${PREFIX}-realtyType`,
    realtyRemove: `${PREFIX}-realtyRemove`,

    autoAdd: `${PREFIX}-autoAdd`,
    autoProducer: `${PREFIX}-autoProducer`,
    autoModel: `${PREFIX}-autoModel`,
    autoYear: `${PREFIX}-autoYear`,
    autoNumber: `${PREFIX}-autoNumber`,
    autoRemove: `${PREFIX}-autoRemove`,

    tinAdd: `${PREFIX}-tinAdd`,
    tinRemove: `${PREFIX}-tinRemove`,
    tinSeries: `${PREFIX}-tinSeries`,
    tinNumber: `${PREFIX}-tinNumber`,
    tinInn: `${PREFIX}-tinInn`,
    tinDate: `${PREFIX}-tinDate`,

    driverAdd: `${PREFIX}-driverAdd`,
    driverRemove: `${PREFIX}-driverRemove`,
    driverSeries: `${PREFIX}-driverSeries`,
    driverNumber: `${PREFIX}-driverNumber`,
    driverBy: `${PREFIX}-driverBy`,
    driverDate: `${PREFIX}-driverDate`,
    driverDateBefore: `${PREFIX}-driverDateBefore`,

    snilsAdd: `${PREFIX}-snilsAdd`,
    snilsRemove: `${PREFIX}-snilsRemove`,
    snilsNumber: `${PREFIX}-snilsNumber`
  },

  pdAgreement: {
    next: `${PREFIX}-pdAgreement-next`,
    camera: `${PREFIX}-pdAgreement-camera`,
    upload: `${PREFIX}-pdAgreement-upload`,
    print: `${PREFIX}-pdAgreement-print`,
    url: `${PREFIX}-pdAgreement-url`
  },

  confirmPhone: {
    sendCode: `${PREFIX}-confirmPhone-sendCode`,
    code: `${PREFIX}-confirmPhone-code`
  },

  credit: {
    expand: `${PREFIX}-credit-expand`,
    label: `${PREFIX}-credit-label`
  },

  creditRequest: {
    expand: `${PREFIX}-creditRequest-expand`,
    clientDataMain: `${PREFIX}-creditRequest-clientData`,
    point: `${PREFIX}-questionary-point`,
    user: `${PREFIX}-questionary-user`,
    clientData: {
      fio: `${PREFIX}-creditRequest-data`,
      phone: `${PREFIX}-creditRequest-phone`,
      passport: `${PREFIX}-creditRequest-passport`,
      type: `${PREFIX}-creditRequest-type`
    }
  },

  product: {
    selectAction: {
      select: (itemID) => `${PREFIX}-product-selectAction-select-${itemID}`,
      continueDate: (itemID) => `${PREFIX}-product-selectAction-continueDate-${itemID}`,
      continueGarant: (itemID) => `${PREFIX}-product-selectAction-continueGarant-${itemID}`
    },
    documentsDownload: {
      download: (itemID) => `${PREFIX}-product-documentsDownload-download-${itemID}`
    },
    documentsUpload: {
      upload: (itemID) => `${PREFIX}-product-documentsUpload-upload-${itemID}`
    }
  }
}
