const PREFIX = 'questionary-list'

export default {
  search: {
    input: `${PREFIX}-search-input`,
    filter: `${PREFIX}-search-filter`,
    searchIcon: `${PREFIX}-search-icon`,
    button: `${PREFIX}-search-button`,
    status: `${PREFIX}-search-status`,
    filterFields: {
      htmlID: `${PREFIX}-filterFields-container`,

      borrower_fullname: `${PREFIX}-filterFields-borrower_fullname`,
      point_name: `${PREFIX}-filterFields-point_name`,
      partner_name: `${PREFIX}-filterFields-partner_name`,
      partner_tin: `${PREFIX}-filterFields-partner_tin`,
      owner_fullname: `${PREFIX}-filterFields-owner_fullname`,
      owner_login: `${PREFIX}-filterFields-owner_login`,
      contract_no: `${PREFIX}-filterFields-contract_no`
    }
  },
  notAuthorization: {
    show: `${PREFIX}-notAuthorization-show`,
    hide: `${PREFIX}-notAuthorization-hide`
  },
  list: {
    htmlID: `${PREFIX}-list`,

    name: `${PREFIX}-list-name`,
    point: `${PREFIX}-list-point`,
    types: `${PREFIX}-list-types`,
    status: `${PREFIX}-list-status`,
    duplicate: `${PREFIX}-list-duplicate`
  }
}
