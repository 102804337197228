const PREFIX = 'auth'
const PREFIX_RESTORE = `${PREFIX}-restore`

export default {
  username: `${PREFIX}-username`,
  password: `${PREFIX}-password`,
  passwordConfirm: `${PREFIX}-passwordConfirm`,
  signIn: `${PREFIX}-signIn`,
  signUpPartner: `${PREFIX}-signUpPartner`,
  restorePassword: `${PREFIX}-restorePassword`,

  pointSelect: `${PREFIX}-pointSelect`,
  restore: {
    smsCode: `${PREFIX_RESTORE}-smsCode`,
    send: `${PREFIX_RESTORE}-send`,
    submit: `${PREFIX_RESTORE}-submit`
  }
}
