const PREFIX = 'acceptance-details'

export default {
  toActList: `${PREFIX}-toActList`,
  prepare: {
    dateFrom: `${PREFIX}-prepare-dateFrom`,
    dateTo: `${PREFIX}-prepare-dateTo`,
    bank: `${PREFIX}-prepare-bank`,
    allPoints: `${PREFIX}-prepare-allPoints`,
    points: `${PREFIX}-prepare-points`,
    receiverType: `${PREFIX}-prepare-receiverType`,
    receiver: `${PREFIX}-prepare-receiver`,
    show: `${PREFIX}-prepare-show`
  },
  list: {
    htmlID: `${PREFIX}-list`,

    itemCheck: `${PREFIX}-list-item-check`,
    itemNumber: `${PREFIX}-list-item-number`,
    itemDate: `${PREFIX}-list-item-date`,
    itemFullName: `${PREFIX}-list-item-fullName`,
    itemSum: `${PREFIX}-list-item-sum`,
    itemDateQuestionary: `${PREFIX}-list-item-dateQuestionary`
  },
  resultAcceptances: `${PREFIX}-resultAcceptances`,
  resultAcceptancesInActs: `${PREFIX}-resultAcceptancesInActs`,
  save: `${PREFIX}-save`,
  applySave: `${PREFIX}-applySave`,
  cancelSave: `${PREFIX}-cancelSave`,
  downloadIntersection: `${PREFIX}-downloadIntersection`,
  downloadClose: `${PREFIX}-downloadClose`,
  constraints: {
    ok: `${PREFIX}-constraints-ok`,
    number: `${PREFIX}-constraints-number`,
    max: `${PREFIX}-constraints-max`
  }
}
