const PREFIX = 'partner'

const PREFIX_SIGN_UP = `${PREFIX}-signUp`
const PREFIX_SIGN_UP_CONFIRM_PHONE = `${PREFIX_SIGN_UP}-confirmPhone`
const PREFIX_SIGN_UP_SUCCESS = `${PREFIX_SIGN_UP}-success`

const PREFIX_WELCOME = `${PREFIX}-welcome`

const PREFIX_LIST = `${PREFIX}-list`

const PREFIX_DETAILS = `${PREFIX}-details`
const PREFIX_DETAILS_PREVIEW = `${PREFIX_DETAILS}-preview`

export default {
  welcome: {
    templateLink: `${PREFIX_WELCOME}-templateLink`,
    templateLinkInList: `${PREFIX_WELCOME}-templateLinkInList`,
    commonLink: `${PREFIX_WELCOME}-commonLink`,
    propositionNewLink: `${PREFIX_WELCOME}-propositionNewLink`
  },

  signUp: {
    lastName: `${PREFIX_SIGN_UP}-lastName`,
    firstName: `${PREFIX_SIGN_UP}-firstName`,
    patronymic: `${PREFIX_SIGN_UP}-patronymic`,
    email: `${PREFIX_SIGN_UP}-email`,
    phone: `${PREFIX_SIGN_UP}-phone`,
    password: `${PREFIX_SIGN_UP}-password`,
    repeatPassword: `${PREFIX_SIGN_UP}-repeatPassword`,
    submit: `${PREFIX_SIGN_UP}-submit`,

    confirmPhone: {
      info: `${PREFIX_SIGN_UP_CONFIRM_PHONE}-info`,
      smsCode: `${PREFIX_SIGN_UP_CONFIRM_PHONE}-smsCode`,
      send: `${PREFIX_SIGN_UP_CONFIRM_PHONE}-send`,
      submit: `${PREFIX_SIGN_UP_CONFIRM_PHONE}-submit`
    },

    success: {
      agreement: `${PREFIX_SIGN_UP_SUCCESS}-agreement`,
      toProposition: `${PREFIX_SIGN_UP_SUCCESS}-toProposition`
    }
  },

  list: {
    table: `${PREFIX_LIST}-table`,
    link: `${PREFIX_LIST}-link`,
    number: `${PREFIX_LIST}-number`,
    createdAt: `${PREFIX_LIST}-createdAt`,
    status: `${PREFIX_LIST}-status`,
    download: `${PREFIX_LIST}-download`
  },

  details: {
    propositionsLink: `${PREFIX_DETAILS}-propositionsLink`,
    headerTitle: `${PREFIX_DETAILS}-headerTitle`,
    back: `${PREFIX_DETAILS}-back`,
    next: `${PREFIX_DETAILS}-next`,

    organization: `${PREFIX_DETAILS}-organization`,
    inn: `${PREFIX_DETAILS}-inn`,
    kpp: `${PREFIX_DETAILS}-kpp`,
    ogrn: `${PREFIX_DETAILS}-ogrn`,
    ogrnDate: `${PREFIX_DETAILS}-ogrnDate`,
    address: `${PREFIX_DETAILS}-address`,
    managerFullName: `${PREFIX_DETAILS}-managerFullName`,
    activityType: `${PREFIX_DETAILS}-activityType`,
    proxyNumber: `${PREFIX_DETAILS}-proxyNumber`,
    proxyDate: `${PREFIX_DETAILS}-proxyDate`,
    taxType: `${PREFIX_DETAILS}-taxType`,
    bank: `${PREFIX_DETAILS}-bank`,
    rs: `${PREFIX_DETAILS}-rs`,
    addBankAccount: `${PREFIX_DETAILS}-addBankAccount`,

    addPoint: `${PREFIX_DETAILS}-addPoint`,
    pointAddress: `${PREFIX_DETAILS}-pointAddress`,
    pointWebAddress: `${PREFIX_DETAILS}-pointWebAddress`,
    pointSegment: `${PREFIX_DETAILS}-pointSegment`,
    bankAccountId: `${PREFIX_DETAILS}-bankAccountId`,
    removePoint: `${PREFIX_DETAILS}-removePoint`,

    addUser: `${PREFIX_DETAILS}-addUser`,
    userFio: `${PREFIX_DETAILS}-userFio`,
    userPassport: `${PREFIX_DETAILS}-userPassport`,
    userPassportDate: `${PREFIX_DETAILS}-userPassportDate`,
    userPassportCode: `${PREFIX_DETAILS}-userPassportCode`,
    userPassportBy: `${PREFIX_DETAILS}-userPassportBy`,
    userBirthPlace: `${PREFIX_DETAILS}-userBirthPlace`,
    userBirthDate: `${PREFIX_DETAILS}-userBirthDate`,
    userAddressRegistration: `${PREFIX_DETAILS}-userAddressRegistration`,
    userPoints: `${PREFIX_DETAILS}-userPoints`,
    userContactPhone: `${PREFIX_DETAILS}-userContactPhone`,
    userEmail: `${PREFIX_DETAILS}-userEmail`,
    removeUser: `${PREFIX_DETAILS}-removeUser`,
    saveUser: `${PREFIX_DETAILS}-saveUser`,
    userInfoRequiredSaveUser: `${PREFIX_DETAILS}-userInfoRequiredSaveUser`,
    userInfoRequiredAgreement: `${PREFIX_DETAILS}-userInfoRequiredAgreement`,
    userAgreementLink: `${PREFIX_DETAILS}-userAgreementLink`,
    userInfoAgreementDownloaded: `${PREFIX_DETAILS}-userInfoAgreementDownloaded`,
    userInfoAgreementSigned: `${PREFIX_DETAILS}-userInfoAgreementSigned`,
    userAgreementDownloadLink: `${PREFIX_DETAILS}-userAgreementDownloadLink`,
    userUploadAgreement: `${PREFIX_DETAILS}-userUploadAgreement`,

    agreementEmail: `${PREFIX_DETAILS}-agreementEmail`,
    agreementPhone: `${PREFIX_DETAILS}-agreementPhone`,
    agreementFio: `${PREFIX_DETAILS}-agreementFio`,
    agreementLink: `${PREFIX_DETAILS}-agreementLink`,
    agreementInfoRequiredConfirm: `${PREFIX_DETAILS}-agreementInfoRequiredConfirm`,
    agreementInfoConfirm: `${PREFIX_DETAILS}-agreementInfoConfirm`,
    agreementSendCode: `${PREFIX_DETAILS}-agreementSendCode`,
    agreementConfirmCode: `${PREFIX_DETAILS}-agreementConfirmCode`,
    agreementSesLink: `${PREFIX_DETAILS}-agreementSesLink`,
    agreementCommonLink: `${PREFIX_DETAILS}-agreementCommonLink`
  },

  detailsPreview: {
    organization: `${PREFIX_DETAILS_PREVIEW}-organization`,
    inn: `${PREFIX_DETAILS_PREVIEW}-inn`,
    kpp: `${PREFIX_DETAILS_PREVIEW}-kpp`,
    ogrn: `${PREFIX_DETAILS_PREVIEW}-ogrn`,
    address: `${PREFIX_DETAILS_PREVIEW}-address`,
    webAdress: `${PREFIX_DETAILS_PREVIEW}-webAdress`,
    phone: `${PREFIX_DETAILS_PREVIEW}-phone`,
    email: `${PREFIX_DETAILS_PREVIEW}-email`,
    agreementLink: `${PREFIX_DETAILS_PREVIEW}-agreementLink`,
    state: `${PREFIX_DETAILS_PREVIEW}-state`,
    tax: `${PREFIX_DETAILS_PREVIEW}-tax`,
    managerFullName: `${PREFIX_DETAILS_PREVIEW}-managerFullName`,
    activityType: `${PREFIX_DETAILS_PREVIEW}-activityType`,

    bankName: `${PREFIX_DETAILS_PREVIEW}-bankName`,
    bankBik: `${PREFIX_DETAILS_PREVIEW}-bankBik`,
    check: `${PREFIX_DETAILS_PREVIEW}-check`,

    pointAddress: `${PREFIX_DETAILS_PREVIEW}-pointAddress`,

    userFio: `${PREFIX_DETAILS_PREVIEW}-userFio`,
    userPassport: `${PREFIX_DETAILS_PREVIEW}-userPassport`,
    userPassportDate: `${PREFIX_DETAILS_PREVIEW}-userPassportDate`,
    userPassportCode: `${PREFIX_DETAILS_PREVIEW}-userPassportCode`,
    userPassportBy: `${PREFIX_DETAILS_PREVIEW}-userPassportBy`,
    userBirthDate: `${PREFIX_DETAILS_PREVIEW}-userBirthDate`,
    userBirthPlace: `${PREFIX_DETAILS_PREVIEW}-userBirthPlace`,
    userAddressRegistration: `${PREFIX_DETAILS_PREVIEW}-userAddressRegistration`,
    userAgreementLink: `${PREFIX_DETAILS_PREVIEW}-userAgreementLink`,
    userContactPhone: `${PREFIX_DETAILS_PREVIEW}-userContactPhone`
  }
}
