const PREFIX = 'bankMix'

export default {
  bank: `${PREFIX}-bank`,
  add: `${PREFIX}-add`,
  applyToPartner: `${PREFIX}-apply-to-partner`,
  insuranceDiscount: `${PREFIX}-insurance-discount`,
  insuranceInitialPayment: `${PREFIX}-insurance-initial-payment`,
  initialPaymentVolatility: `${PREFIX}-initial-payment-volatility`,
  save: `${PREFIX}-save`,
  delete: `${PREFIX}-delete`,
  deleteConfirm: `${PREFIX}-deleteConfirm`,
  partner: `${PREFIX}-partner`,
  new: `${PREFIX}-new`,
  bankPiece: {
    remove: (bankID) => `${PREFIX}-remove-${bankID}`,
    percentage: (bankID) => `${PREFIX}-percentage-${bankID}`,
    hard: (bankID) => `${PREFIX}-hard-${bankID}`,
    insuranceStrategy: (bankID) => `${PREFIX}-insurance-strategy-${bankID}`,
    insurancePenetrationPercentage: (bankID) => `${PREFIX}-insurance-penetration-percentage-${bankID}`,
    bankInsurancePercentage: (bankID) => `${PREFIX}-bank-insurance-percentage-${bankID}`,
    externalInsurancePercentage: (bankID) => `${PREFIX}-external-insurance-percentage-${bankID}`,
    smsStrategy: (bankID) => `${PREFIX}-sms-strategy-${bankID}`,
    smsPenetrationPercentage: (bankID) => `${PREFIX}-sms-penetration-percentage-${bankID}`,
    bankSmsPercentage: (bankID) => `${PREFIX}-bank-sms-percentage-${bankID}`,
    externalSmsPercentage: (bankID) => `${PREFIX}-external-sms-percentage-${bankID}`,
    creditProductsSelect: (bankID) => `${PREFIX}-credit-products-select-${bankID}`,
    installmentProductsSelect: (bankID) => `${PREFIX}-installment-products-select-${bankID}`,
    insuranceProductsSelect: (bankID) => `${PREFIX}-insurance-products-select-${bankID}`,
    additionalServiceSelect: (bankID) => `${PREFIX}-additional-service-select-${bankID}`,
    addAdditionalService: (bankID) => `${PREFIX}-add-additional-service-${bankID}`,
    serviceStrategy: {
      name: (bankID, serviceID) => `${PREFIX}-additional-service-name-${bankID}-${serviceID}`,
      penetrationPercentage: (bankID, serviceID) => `${PREFIX}-additional-service-penetration-percentage-${bankID}-${serviceID}`,
      amountMin: (bankID, serviceID) => `${PREFIX}-additional-service-amount-min-${bankID}-${serviceID}`,
      amountMax: (bankID, serviceID) => `${PREFIX}-additional-service-amount-max-${bankID}-${serviceID}`,
      remove: (bankID, serviceID) => `${PREFIX}-additional-service-remove-${bankID}-${serviceID}`
    }
  }
}
