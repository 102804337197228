const PREFIX = 'organizations-list'

export default {
  prepare: {
    searchInput: `${PREFIX}-prepare-searchInput`,
    isActive: `${PREFIX}-prepare-isActive`
  },
  add: `${PREFIX}-add`,
  list: {
    htmlID: `${PREFIX}-list`,

    id: `${PREFIX}-list-id`,
    name: `${PREFIX}-list-name`,
    inn: `${PREFIX}-list-inn`,
    dateTurnOff: `${PREFIX}-list-dateTurnOff`,
    isApplicationUploaded: `${PREFIX}-list-isApplicationUploaded`,
    isActiveLock: `${PREFIX}-list-isActive-lock`,
    isActiveUnlock: `${PREFIX}-list-isActive-unlock`
  }
}
