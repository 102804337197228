const PREFIX = 'menu'

export default {
  menuItem: `${PREFIX}-menuItem`,
  settingsItem: `${PREFIX}-settingsItem`,

  submenuHeader: `${PREFIX}-submenuHeader`,
  submenuProfile: `${PREFIX}-submenuProfile`,
  submenuIdea: `${PREFIX}-submenuIdea`,
  submenuRegistries: `${PREFIX}-submenuRegistries`,
  submenuDailyReport: `${PREFIX}-submenuDailyReport`,
  submenuAccreditation: `${PREFIX}-submenuAccreditation`,
  submenuMotivation: `${PREFIX}-submenuMotivation`,
  submenuRewards: `${PREFIX}-submenuRewards`,
  submenuLogout: `${PREFIX}-submenuLogout`
}
