const PREFIX = 'call-center'

export default {
  confirmPhone: {
    code: `${PREFIX}-confirmPhone-code`
  },
  preview: {
    fio: `${PREFIX}-fio`,
    mobilePhone: `${PREFIX}-mobilePhone`,
    point: `${PREFIX}-point`

  },
  goods: {
    name: `${PREFIX}-name`
  }

}
