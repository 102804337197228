const PREFIX = 'points-list'

export default {
  prepare: {
    searchInput: `${PREFIX}-prepare-searchInput`,
    isActive: `${PREFIX}-prepare-isActive`,
    isOnline: `${PREFIX}-prepare-isOnline`
  },
  add: `${PREFIX}-add`,
  list: {
    htmlID: `${PREFIX}-list`,

    id: `${PREFIX}-list-id`,
    name: `${PREFIX}-list-name`,
    address: `${PREFIX}-list-address`,
    accessLock: `${PREFIX}-list-access-lock`,
    accessUnlock: `${PREFIX}-list-access-unlock`,
    lockReason: `${PREFIX}-list-lock-reason`,
    saveReason: `${PREFIX}-list-save-reason`
  }
}
