const PREFIX = 'user-details'

export default {
  editContainer: `${PREFIX}-editContainer`,

  login: `${PREFIX}-login`,
  lastName: `${PREFIX}-lastName`,
  firstName: `${PREFIX}-firstName`,
  patronymic: `${PREFIX}-patronymic`,
  phone: `${PREFIX}-phone`,
  email: `${PREFIX}-email`,
  password: `${PREFIX}-password`,
  selfPassword: `${PREFIX}-selfPassword`,
  manager: `${PREFIX}-manager`,
  accessRole: `${PREFIX}-accessRole`,
  workerType: `${PREFIX}-workerType`,
  status: `${PREFIX}-status`,
  hireType: `${PREFIX}-hireType`,
  points: `${PREFIX}-points`,
  callCenters: `${PREFIX}-callCenters`,
  qrSpec: `${PREFIX}-qrSpec`,
  macroRegions: `${PREFIX}-macroRegions`,
  regions: `${PREFIX}-rrrrrr`,
  organizations: `${PREFIX}-organizations`,
  shortQuestionaryPoints: `${PREFIX}-shortQuestionaryPoints`,
  saveUser: `${PREFIX}-saveUser`,
  showAgentCommission: `${PREFIX}-showAgentCommission`,
  isSendInvite: `${PREFIX}-isSendInvite`,
  isSendConfirmation: `${PREFIX}-isSendConfirmation`,
  codes: {
    bank: `${PREFIX}-codes-bank`,
    add: `${PREFIX}-codes-add`,
    value: `${PREFIX}-codes-value`,
    delete: `${PREFIX}-codes-delete`,
    saveCodes: `${PREFIX}-codes-saveCodes`
  },

  history: {
    list: `${PREFIX}-history-list`,
    user: `${PREFIX}-history-list-user`,
    time: `${PREFIX}-history-list-time`,
    changes: `${PREFIX}-history-list-changes`
  },

  AgentCommissionGroups: {
    block: `${PREFIX}-agent-commission-groups-block`,
    select: `${PREFIX}-agent-commission-groups-select`,
    add: `${PREFIX}-agent-commission-groups-add`,
    startsOn: `${PREFIX}-agent-commission-starts-on`,
    save: `${PREFIX}-agent-commission-save`
  }
}
