const PREFIX = 'refund'

export default {
  root: `${PREFIX}-root`,
  instructions: `${PREFIX}-instructions`,
  internalRejectReason: `${PREFIX}-internalRejectReason`,
  adminUrlBlock: `${PREFIX}-adminUrlBlock`,
  documents: {
    root: `${PREFIX}-documents-root`,
    document: `${PREFIX}-documents-document`,
    download: `${PREFIX}-documents-download`,
    upload: `${PREFIX}-documents-upload`,
    cancel: `${PREFIX}-documents-cancel`,
    review: `${PREFIX}-documents-review`
  }
}
