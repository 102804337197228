const PREFIX = 'credit-requests'

export default {
  actualScope: `${PREFIX}-creditRequestScopeActual`,
  allScope: `${PREFIX}-creditRequestScopeAll`,
  myScope: `${PREFIX}-creditRequestScopeMy`,
  table: `${PREFIX}-creditRequestTable`,
  scopesToggler: `${PREFIX}-creditRequestScopes`,
  search: `${PREFIX}-search`,

  list: {
    htmlID: `${PREFIX}-list`,

    fio: `${PREFIX}-list-fio`,
    source: `${PREFIX}-list-source`,
    pointName: `${PREFIX}-list-pointName`,
    phoneNumber: `${PREFIX}-list-phoneNumber`,
    state: `${PREFIX}-list-state`,
    operator: `${PREFIX}-list-operator`,
    actions: `${PREFIX}-list-actions`,
    previewLink: `${PREFIX}-previewLink`
  }
}
