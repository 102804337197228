const PREFIX = 'creditRequest'

export default {
  show: {
    block: `${PREFIX}-block`,
    client: `${PREFIX}-client`,
    order: `${PREFIX}-order`,
    main: `${PREFIX}-main`,
    brief: {
      block: `${PREFIX}-brief-block`,
      noPhoto: `${PREFIX}-brief-noPhoto`,
      photo: `${PREFIX}-brief-photo`,
      mainData: `${PREFIX}-brief-mainData`,
      actions: `${PREFIX}-brief-actions`,
      assignButton: `${PREFIX}-brief-assignButton`,
      unassignButton: `${PREFIX}-brief-unassignButton`,
      cancelButton: `${PREFIX}-brief-cancelButton`
    },
    goods: {
      block: `${PREFIX}-goods-block`,
      table: `${PREFIX}-goods-table`,
      name: `${PREFIX}-goods-name`,
      manufacturer: `${PREFIX}-goods-manufacturer`,
      category: `${PREFIX}-goods-category`,
      price: `${PREFIX}-goods-price`,
      count: `${PREFIX}-goods-count`
    },
    creditParams: {
      block: `${PREFIX}-creditParams-block`,
      requestType: `${PREFIX}-creditParams-requestType`,
      term: `${PREFIX}-creditParams-term`,
      initialPayment: `${PREFIX}-creditParams-initialPayment`
    },
    comment: {
      block: `${PREFIX}-comment-block`,
      text: `${PREFIX}-comment-text`
    }
  }
}
