const PREFIX = 'pickup'

export default {
  credit: `${PREFIX}-credit`,
  discount: `${PREFIX}-discount`,
  term: `${PREFIX}-term`,
  initialPayment: `${PREFIX}-initialPayment`,
  cost: `${PREFIX}-cost`,
  getResult: `${PREFIX}-getResult`,

  shortOffers: {
    prefix: `${PREFIX}-shortOffers`,

    isTipsSum: `${PREFIX}-shortOffers-isTipsSum`,
    sortTerm: `${PREFIX}-shortOffers-sort-term`,
    sortInitialPayment: `${PREFIX}-shortOffers-sort-initialPayment`,
    sortMonthly: `${PREFIX}-shortOffers-sort-monthly`,
    sortTipsSum: `${PREFIX}-shortOffers-sort-TipsSum`,
    monthlySelect: `${PREFIX}-shortOffers-monthly-select`,

    offerTerm: `${PREFIX}-shortOffers-offer-term`,
    offerTermWait: `${PREFIX}-shortOffers-offer-term-wait`,
    offerInitialPayment: `${PREFIX}-shortOffers-offer-initialPayment`,
    offerInitialPaymentWait: `${PREFIX}-shortOffers-offer-initialPayment-wait`,
    offerMonthly: `${PREFIX}-shortOffers-offer-monthly`,
    offerMonthlyWait: `${PREFIX}-shortOffers-offer-monthly-wait`,
    offerTipsSum: `${PREFIX}-shortOffers-offer-TipsSum`,
    offerTipsSumWait: `${PREFIX}-shortOffers-offer-TipsSum-wait`,

    offerServices: `${PREFIX}-shortOffers-offer-services`,
    offerServicesWait: `${PREFIX}-shortOffers-offer-services-wait`,
    offerShowDetails: `${PREFIX}-shortOffers-offer-showDetails`,

    offerMissProducts: `${PREFIX}-shortOffers-offer-missProducts`
  },
  detailsOffers: {
    prefix: `${PREFIX}-detailsOffers`,

    isTipsSum: `${PREFIX}-detailsOffers-isTipsSum`,
    sortTerm: `${PREFIX}-detailsOffers-sort-term`,
    sortInitialPayment: `${PREFIX}-detailsOffers-sort-initialPayment`,
    sortMonthly: `${PREFIX}-detailsOffers-sort-monthly`,
    sortTipsSum: `${PREFIX}-detailsOffers-sort-TipsSum`,
    monthlySelect: `${PREFIX}-detailsOffers-monthly-select`,

    offerTerm: `${PREFIX}-detailsOffers-offer-term`,
    offerTermWait: `${PREFIX}-detailsOffers-offer-term-wait`,
    offerInitialPayment: `${PREFIX}-detailsOffers-offer-initialPayment`,
    offerInitialPaymentWait: `${PREFIX}-detailsOffers-offer-initialPayment-wait`,
    offerMonthly: `${PREFIX}-detailsOffers-offer-monthly`,
    offerMonthlyWait: `${PREFIX}-detailsOffers-offer-monthly-wait`,
    offerTipsSum: `${PREFIX}-detailsOffers-offer-TipsSum`,
    offerTipsSumWait: `${PREFIX}-detailsOffers-offer-TipsSum-wait`,

    offerProductName: `${PREFIX}-detailsOffers-offer-productName`,
    offerIsSelect: `${PREFIX}-detailsOffers-offer-isSelect`,
    offerSelect: `${PREFIX}-detailsOffers-offer-select`
  }
}
