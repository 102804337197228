const PREFIX = 'users-list'

export default {
  add: `${PREFIX}-add`,
  search: {
    input: `${PREFIX}-search-input`,
    isActive: `${PREFIX}-search-isActive`
  },
  list: {
    htmlID: `${PREFIX}-list`,

    login: `${PREFIX}-list-login`,
    fullName: `${PREFIX}-list-fullName`,
    role: `${PREFIX}-list-role`,
    type: `${PREFIX}-list-type`,
    accessLock: `${PREFIX}-list-access-lock`,
    accessUnlock: `${PREFIX}-list-access-unlock`,
    signInUser: `${PREFIX}-list-signInUser`,
    lockReason: `${PREFIX}-list-lock-reason`,
    saveReason: `${PREFIX}-list-save-reason`
  }
}
