const PREFIX = 'partnerForm'

export default {
  organizationName: `${PREFIX}-organizationName`,
  city: `${PREFIX}-city`,
  sendButton: `${PREFIX}-sendButton`,
  typeOfActivity: `${PREFIX}-typeOfActivity`,
  phone: `${PREFIX}-phone`,
  email: `${PREFIX}-email`
}
