const PREFIX = 'acceptance-list'

export default {
  add: `${PREFIX}-add`,
  prepare: {
    searchInput: `${PREFIX}-prepare-searchInput`,
    search: `${PREFIX}-prepare-search`,
    filter: {
      htmlID: `${PREFIX}-prepare-filter`,

      contract_no: `${PREFIX}-prepare-filter-contract_no`,
      number: `${PREFIX}-prepare-filter-number`,
      created_at: `${PREFIX}-prepare-filter-created_at`,
      bank: `${PREFIX}-prepare-filter-bank`,
      author: `${PREFIX}-prepare-filter-author`
    },
    receiverType: `${PREFIX}-prepare-receiverType`
  },
  list: {
    htmlID: `${PREFIX}-list`,

    number: `${PREFIX}-list-number`,
    date: `${PREFIX}-list-date`,
    receiverType: `${PREFIX}-list-receiverType`,
    receiver: `${PREFIX}-list-receiver`,
    author: `${PREFIX}-list-author`,
    bank: `${PREFIX}-list-bank`,
    period: `${PREFIX}-list-period`,
    count: `${PREFIX}-list-count`,
    status: `${PREFIX}-list-status`,
    downloadAct: `${PREFIX}-list-downloadAct`,
    uploadAct: `${PREFIX}-list-uploadAct`,
    downloadScan: `${PREFIX}-list-downloadScan`,
    removeActScan: `${PREFIX}-list-removeActScan`
  }
}
