const PREFIX = 'agent-reward'

export default {
  list: {
    user: `${PREFIX}-list-user`,
    creditsCount: `${PREFIX}-list-credits-count`,
    refundsCount: `${PREFIX}-list-refunds-count`,
    amount: `${PREFIX}-list-amount`
  },
  details: {
    noClosedNotice: `${PREFIX}-details-not-closed`,
    date: `${PREFIX}-details-date`,
    questionaryId: `${PREFIX}-details-questionary-id`,
    point: `${PREFIX}-details-point`,
    bank: `${PREFIX}-details-bank`,
    creditTax: `${PREFIX}-details-credit-tax`,
    creditAmount: `${PREFIX}-details-credit-amount`,
    bankReward: `${PREFIX}-details-bank-reward`,
    insuranceReward: `${PREFIX}-details-insurance-reward`,
    moblawReward: `${PREFIX}-details-moblaw-reward`,
    telemedReward: `${PREFIX}-details-telemed-reward`,
    smsReward: `${PREFIX}-details-sms-reward`,
    totalReward: `${PREFIX}-details-total-reward`,
    total: `${PREFIX}-details-total`,
    additionalServicesAmount: `${PREFIX}-details-additional-services-amount`
  }
}
